.buttontext{font-size:19px;font-weight:400;color:#fff}
.mainbrouchardiv{
    /* background-image: url('/path_to_background_image.jpg'); */
    background-size: cover;
    width: 276px;
    height: 42px;
    margin: auto;
    border-radius: 24px;
    align-items: center;
    background-color: #4d96ae;
    text-align: center;
}
.contactbg{padding-bottom:50px;background-image:linear-gradient(rgb(0 0 0 / 0%),rgb(247 247 247 / 19%)),url("../../image/contact-Bg.jpg");margin-bottom:-3px;margin-top:-3px;background-size:cover}.inputContact,.inputContact:focus{background-color:#f0f8ff42;border:1px solid #9da6af;color:#fff}.inputContact .ant-input-number-input{color:#fff;padding:0}::placeholder{color:#fff!important;opacity:1}.buttonsubmistyle{margin-top:10px}.contactmaintitle{padding-top:30px}.inputtitle{font-family:emoji;font-size:18px!important}.inputContact :-ms-input-placeholder{color:red}.inputContact ::-ms-input-placeholder{color:red}.contacttitle{font-family:Krylon,Sans-serif;font-size:50px;font-weight:400;font-style:oblique;color:#fff;text-shadow:1px 2px #120c0c}.textincontactp{color:#fff;font-size:17px;font-weight:600;text-shadow:3px 4px 11px #120c0c}.maintitle{font-weight:800;color:#fff;text-shadow:1px 2px #120c0c}
.hidden {
    display: none;
  }